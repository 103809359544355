import React, { useEffect, useState } from 'react';
// @ts-ignore 
import TheDog from './dog/wtdd.mp4';
import './App.css';

function App() {
  const [muted, setMuted] = useState(true);

  useEffect(() => {
    // @ts-ignore 
    let isChromium = window.chrome;
    let vendorName = window.navigator.vendor;

    if (
      isChromium !== null &&
      typeof isChromium !== "undefined" &&
      vendorName === "Google Inc."
    ) {
      setMuted(true);
    } else {
      setMuted(false);
    }
  }, [])

  return (
    <div className="App">
      <div id="wtdd">
      {muted ? <div id="mutedwarn">Click to unmute!</div> : false}
        <video autoPlay loop controls={false} muted={muted} onClick={() => setMuted(false)}> 
          <source src={TheDog} type="video/mp4" />
        </video>
      </div>
    </div>
  );
}

export default App;
